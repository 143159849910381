var monokai = Object.freeze({
  "colors": {
    "activityBar.background": "#272822",
    "activityBar.foreground": "#f8f8f2",
    "badge.background": "#75715E",
    "badge.foreground": "#f8f8f2",
    "button.background": "#75715E",
    "debugToolBar.background": "#1e1f1c",
    "diffEditor.insertedTextBackground": "#4b661680",
    "diffEditor.removedTextBackground": "#90274A70",
    "dropdown.background": "#414339",
    "dropdown.listBackground": "#1e1f1c",
    "editor.background": "#272822",
    "editor.foreground": "#f8f8f2",
    "editor.lineHighlightBackground": "#3e3d32",
    "editor.selectionBackground": "#878b9180",
    "editor.selectionHighlightBackground": "#575b6180",
    "editor.wordHighlightBackground": "#4a4a7680",
    "editor.wordHighlightStrongBackground": "#6a6a9680",
    "editorCursor.foreground": "#f8f8f0",
    "editorGroup.border": "#34352f",
    "editorGroup.dropBackground": "#41433980",
    "editorGroupHeader.tabsBackground": "#1e1f1c",
    "editorHoverWidget.background": "#414339",
    "editorHoverWidget.border": "#75715E",
    "editorIndentGuide.activeBackground": "#767771",
    "editorIndentGuide.background": "#464741",
    "editorLineNumber.activeForeground": "#c2c2bf",
    "editorLineNumber.foreground": "#90908a",
    "editorSuggestWidget.background": "#272822",
    "editorSuggestWidget.border": "#75715E",
    "editorWhitespace.foreground": "#464741",
    "editorWidget.background": "#1e1f1c",
    "focusBorder": "#99947c",
    "input.background": "#414339",
    "inputOption.activeBorder": "#75715E",
    "inputValidation.errorBackground": "#90274A",
    "inputValidation.errorBorder": "#f92672",
    "inputValidation.infoBackground": "#546190",
    "inputValidation.infoBorder": "#819aff",
    "inputValidation.warningBackground": "#848528",
    "inputValidation.warningBorder": "#e2e22e",
    "list.activeSelectionBackground": "#75715E",
    "list.dropBackground": "#414339",
    "list.highlightForeground": "#f8f8f2",
    "list.hoverBackground": "#3e3d32",
    "list.inactiveSelectionBackground": "#414339",
    "menu.background": "#1e1f1c",
    "menu.foreground": "#cccccc",
    "minimap.selectionHighlight": "#878b9180",
    "panel.border": "#414339",
    "panelTitle.activeBorder": "#75715E",
    "panelTitle.activeForeground": "#f8f8f2",
    "panelTitle.inactiveForeground": "#75715E",
    "peekView.border": "#75715E",
    "peekViewEditor.background": "#272822",
    "peekViewEditor.matchHighlightBackground": "#75715E",
    "peekViewResult.background": "#1e1f1c",
    "peekViewResult.matchHighlightBackground": "#75715E",
    "peekViewResult.selectionBackground": "#414339",
    "peekViewTitle.background": "#1e1f1c",
    "pickerGroup.foreground": "#75715E",
    "ports.iconRunningProcessForeground": "#ccccc7",
    "progressBar.background": "#75715E",
    "quickInputList.focusBackground": "#414339",
    "selection.background": "#878b9180",
    "settings.focusedRowBackground": "#4143395A",
    "sideBar.background": "#1e1f1c",
    "sideBarSectionHeader.background": "#272822",
    "statusBar.background": "#414339",
    "statusBar.debuggingBackground": "#75715E",
    "statusBar.noFolderBackground": "#414339",
    "statusBarItem.remoteBackground": "#AC6218",
    "tab.border": "#1e1f1c",
    "tab.inactiveBackground": "#34352f",
    "tab.inactiveForeground": "#ccccc7",
    "tab.lastPinnedBorder": "#414339",
    "terminal.ansiBlack": "#333333",
    "terminal.ansiBlue": "#6A7EC8",
    "terminal.ansiBrightBlack": "#666666",
    "terminal.ansiBrightBlue": "#819aff",
    "terminal.ansiBrightCyan": "#66D9EF",
    "terminal.ansiBrightGreen": "#A6E22E",
    "terminal.ansiBrightMagenta": "#AE81FF",
    "terminal.ansiBrightRed": "#f92672",
    "terminal.ansiBrightWhite": "#f8f8f2",
    "terminal.ansiBrightYellow": "#e2e22e",
    "terminal.ansiCyan": "#56ADBC",
    "terminal.ansiGreen": "#86B42B",
    "terminal.ansiMagenta": "#8C6BC8",
    "terminal.ansiRed": "#C4265E",
    "terminal.ansiWhite": "#e3e3dd",
    "terminal.ansiYellow": "#B3B42B",
    "titleBar.activeBackground": "#1e1f1c",
    "widget.shadow": "#00000098"
  },
  "displayName": "Monokai",
  "name": "monokai",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "settings": {
        "foreground": "#F8F8F2"
      }
    },
    {
      "scope": [
        "meta.embedded",
        "source.groovy.embedded",
        "string meta.image.inline.markdown",
        "variable.legacy.builtin.python"
      ],
      "settings": {
        "foreground": "#F8F8F2"
      }
    },
    {
      "scope": "comment",
      "settings": {
        "foreground": "#88846f"
      }
    },
    {
      "scope": "string",
      "settings": {
        "foreground": "#E6DB74"
      }
    },
    {
      "scope": [
        "punctuation.definition.template-expression",
        "punctuation.section.embedded"
      ],
      "settings": {
        "foreground": "#F92672"
      }
    },
    {
      "scope": [
        "meta.template.expression"
      ],
      "settings": {
        "foreground": "#F8F8F2"
      }
    },
    {
      "scope": "constant.numeric",
      "settings": {
        "foreground": "#AE81FF"
      }
    },
    {
      "scope": "constant.language",
      "settings": {
        "foreground": "#AE81FF"
      }
    },
    {
      "scope": "constant.character, constant.other",
      "settings": {
        "foreground": "#AE81FF"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "fontStyle": "",
        "foreground": "#F8F8F2"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#F92672"
      }
    },
    {
      "scope": "storage",
      "settings": {
        "fontStyle": "",
        "foreground": "#F92672"
      }
    },
    {
      "scope": "storage.type",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#66D9EF"
      }
    },
    {
      "scope": "entity.name.type, entity.name.class, entity.name.namespace, entity.name.scope-resolution",
      "settings": {
        "fontStyle": "underline",
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "entity.other.inherited-class",
      "settings": {
        "fontStyle": "italic underline",
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "fontStyle": "",
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "variable.parameter",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#FD971F"
      }
    },
    {
      "scope": "entity.name.tag",
      "settings": {
        "fontStyle": "",
        "foreground": "#F92672"
      }
    },
    {
      "scope": "entity.other.attribute-name",
      "settings": {
        "fontStyle": "",
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "support.function",
      "settings": {
        "fontStyle": "",
        "foreground": "#66D9EF"
      }
    },
    {
      "scope": "support.constant",
      "settings": {
        "fontStyle": "",
        "foreground": "#66D9EF"
      }
    },
    {
      "scope": "support.type, support.class",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#66D9EF"
      }
    },
    {
      "scope": "support.other.variable",
      "settings": {
        "fontStyle": ""
      }
    },
    {
      "scope": "invalid",
      "settings": {
        "fontStyle": "",
        "foreground": "#F44747"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "foreground": "#F44747"
      }
    },
    {
      "scope": "meta.structure.dictionary.json string.quoted.double.json",
      "settings": {
        "foreground": "#CFCFC2"
      }
    },
    {
      "scope": "meta.diff, meta.diff.header",
      "settings": {
        "foreground": "#75715E"
      }
    },
    {
      "scope": "markup.deleted",
      "settings": {
        "foreground": "#F92672"
      }
    },
    {
      "scope": "markup.inserted",
      "settings": {
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "markup.changed",
      "settings": {
        "foreground": "#E6DB74"
      }
    },
    {
      "scope": "constant.numeric.line-number.find-in-files - match",
      "settings": {
        "foreground": "#AE81FFA0"
      }
    },
    {
      "scope": "entity.name.filename.find-in-files",
      "settings": {
        "foreground": "#E6DB74"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "foreground": "#F92672"
      }
    },
    {
      "scope": "markup.list",
      "settings": {
        "foreground": "#E6DB74"
      }
    },
    {
      "scope": "markup.bold, markup.italic",
      "settings": {
        "foreground": "#66D9EF"
      }
    },
    {
      "scope": "markup.inline.raw",
      "settings": {
        "fontStyle": "",
        "foreground": "#FD971F"
      }
    },
    {
      "scope": "markup.heading",
      "settings": {
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "markup.heading.setext",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "markup.heading.markdown",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "markup.quote.markdown",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#75715E"
      }
    },
    {
      "scope": "markup.bold.markdown",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "string.other.link.title.markdown,string.other.link.description.markdown",
      "settings": {
        "foreground": "#AE81FF"
      }
    },
    {
      "scope": "markup.underline.link.markdown,markup.underline.link.image.markdown",
      "settings": {
        "foreground": "#E6DB74"
      }
    },
    {
      "scope": "markup.italic.markdown",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "markup.strikethrough",
      "settings": {
        "fontStyle": "strikethrough"
      }
    },
    {
      "scope": "markup.list.unnumbered.markdown, markup.list.numbered.markdown",
      "settings": {
        "foreground": "#f8f8f2"
      }
    },
    {
      "scope": [
        "punctuation.definition.list.begin.markdown"
      ],
      "settings": {
        "foreground": "#A6E22E"
      }
    },
    {
      "scope": "token.info-token",
      "settings": {
        "foreground": "#6796e6"
      }
    },
    {
      "scope": "token.warn-token",
      "settings": {
        "foreground": "#cd9731"
      }
    },
    {
      "scope": "token.error-token",
      "settings": {
        "foreground": "#f44747"
      }
    },
    {
      "scope": "token.debug-token",
      "settings": {
        "foreground": "#b267e6"
      }
    },
    {
      "scope": "variable.language",
      "settings": {
        "foreground": "#FD971F"
      }
    }
  ],
  "type": "dark"
});

export { monokai as default };
